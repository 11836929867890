.btn {
  padding: 8px;
  border-radius: 4px;
  background-color: inherit;
  border: 1px solid black;
  margin: 0 auto;
  cursor: pointer;
  color: inherit;

  display: flex;
  align-items: center;
  gap: 4px;

  transition: background-color .5s ease;

  &:hover {
    background-color: lightgray;
  }
}