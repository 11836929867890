.grid {
  display: grid;
  grid-template-columns: 1fr 1fr; //Two equal width columns

//  align-items: center;

  .cell1 {
    // text-align: center;
    grid-column: 1; //Trail name and location on left
  }
  .cell2 {
    grid-column: 2; //Trail status on the right
  }
}

.status {
  text-align: center;
  font-weight: bold;
  font-size: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;

  svg {
    width: 10%;
    height: auto;
  }

  .gap {
    height: 16px;
  }
}