.center {
  padding: 8px;
  width: 50%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@media only screen and (orientation: portrait) {
  .center {
    width: 90%;
  }
}